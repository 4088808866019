import React, { useState } from "react";
import { Button, Card, FloatingLabel, Form, Modal, Table } from "react-bootstrap";
import CenterService from "../../services/center.service";
import { ExportToCsv } from "export-to-csv-fix-source-map";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import MyUploader from "../Dropzone/dropzone";
import { useParams } from "react-router-dom";

interface IUploadData {
    show: boolean;
    setShow: any;
    labId: string
}


export default function UploadLabData(props: IUploadData) {

    const params: any = useParams();

    const uploadHandler = async (e: any) => {
        console.log(e)

        const formData = new FormData();
        formData.append("file", e[0]);

        toast.promise(
            CenterService.uploadComputersCSV(formData, params.centerId, props.labId).then(res => {
                if (res.status === 200) {
                    props.setShow(false);
                }
            }), {
            success: "Uploaded Successfully",
            error: "Error while uploading",
            pending: "Uploading..."
        }
        )
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={() => props.setShow(undefined)}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Lab Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <MyUploader
                            fileSelectedHandler={uploadHandler}
                            title="Drag 'n' drop file."
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
