import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../../components/Navbars/SideBar";
import dashboardRoutes from "../../router/routes/dashboard.routes";

export default function Dashboard() {
    
  return (
    <>
      <SideBar 
        routes={dashboardRoutes}
        panelName="Dashboard"
        navUrlPos={2}
        baseUrl="/dashboard/"
        />
      <Outlet />
    </>
  );
}
