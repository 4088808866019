import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useParams } from 'react-router-dom';
import TopBar from '../../../components/Navbars/TopBar';
import XcnComponent from '../../../components/XcnComponent/XcnComponent';
import centerBookingRoutes, { centerBookingBaseUrl } from '../../../router/routes/centerDash/centerBookings.routes';
import centerDetailsRoutes, { centerDetailsBaseUrl } from '../../../router/routes/centerDash/centerDetails.routes';

export default function CenterBookingsIndex() {
  const params: any = useParams();

  return (
    <>
      <TopBar
        routes={centerBookingRoutes}
        baseUrl={centerBookingBaseUrl(params.centerId)}
        navUrlPos={3}
      />

      <XcnComponent
        routes={centerBookingRoutes}
        baseUrl={centerBookingBaseUrl(params.centerId)}
        navUrlPos={3}
      />
      <Outlet />
    </>
  )
}