import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useParams } from 'react-router-dom';
import TopBar from '../../../components/Navbars/TopBar';
import XcnComponent from '../../../components/XcnComponent/XcnComponent';
import centerLabRoutes, { centerLabsBaseUrl } from '../../../router/routes/centerDash/centerLab.routes';

export default function CenterLabIndex(){
    const params: any = useParams();
    
    return (
        <>
          <TopBar
            routes={centerLabRoutes}
            baseUrl={centerLabsBaseUrl(params.centerId)}
            navUrlPos={3}
          />

          <XcnComponent 
            routes={centerLabRoutes}
            baseUrl={centerLabsBaseUrl(params.centerId)}
            navUrlPos={3}
          />
            <Outlet/>
        </>
    )
}