import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import ViewCentersTable from "../../../components/Centers/ViewCenters.table";
import CenterService from "../../../services/center.service";

export default function ViewCenters() {

    const [allCenters, setAllCenters] = useState<any[]>([])


    const getAllCenters = async () => {
        await CenterService.getAllCenters().then(res => {
            if (res.status === 200) {
                setAllCenters(res.data);
            }
        })
    }

    useEffect(() => {
        getAllCenters();
    }, [])

    return (
        <>
            <Container className="xcn-conatiner">
                <Card className="xcn-card mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <span className="text-primary fw-bold fs-5">
                            Registered Centers
                        </span>
                    </div>
                    <ViewCentersTable centers={allCenters} />
                </Card>
            </Container>
        </>
    )
}