import React from 'react';
import { Button, Spinner } from 'react-bootstrap';


interface IXcnButton {
    text: any

    variant?: string
    loadingText?: any
    size?: any
    className?: string
    disabled?: boolean
    loading?: boolean
    onClick?: any
}

export default function XcnButton(props: IXcnButton) {
    return (
        <Button
            variant={props.variant || "primary"}
            className={props.className}
            disabled={props.disabled || props.loading ? true : false}
            onClick={props.onClick}
            size={props.size}
        >
            {
                props.loading ?
                    <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="visually-hidden">{
                            props.loadingText || "Loading..."
                        }</span>
                    </>
                    :
                    props.text
            }
        </Button>
    )
}