import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

interface IInputBox {
    label: string
    type: string
    placeholder?: string
    onChange?: any
    loading?: boolean
    name?: string
    disabled?: boolean
    value?: any
    controlId?: string
    className?: string
    defaultValue?: any
}

export default function InputBox(props: IInputBox) {
    return (
        <FloatingLabel
            controlId={props.controlId}
            label={props.label}
            className={props.className}
        >
            <Form.Control
                type={props.type}
                placeholder={props.placeholder}
                onChange={props.onChange}
                disabled={props.disabled || props.loading ? true : false}
                name={props.name}
                defaultValue={props.defaultValue}
            />
        </FloatingLabel>
    )
}