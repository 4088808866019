import React, { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import AddBooking from '../../../../components/Modals/AddBooking.modal';
import moment from 'moment'

const localizer = momentLocalizer(moment);

export default function ViewBooking() {

    const params: any = useParams();

    const [centerDetails, setCenterDetails] = useState<any>({});
    const [showAddBooking, setShowAddBooking] = useState<boolean>(false);

    const createBooking = async () => {

    }

    useEffect(() => {
    }, [])

    return (
        <>
            <Container className="xcn-container">

                <Card className="xcn-card mt-3">
                    <div className='d-flex justify-content'>
                        <div className=''>
                            <button className='pull-right btn btn-primary' onClick = {() => setShowAddBooking(true)}>Add Booking</button>
                            <AddBooking showAddBooking = {showAddBooking} setShowAddBooking = {setShowAddBooking} />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <Calendar
                            localizer={localizer}
                            events={[]}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 500 }}
                        />
                    </div>
                </Card>
            </Container>
        </>
    )
}