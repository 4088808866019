import React, { useEffect, useState } from "react";
import { Button, Card, FloatingLabel, Form, Modal, Table } from "react-bootstrap";
import CenterService from "../../services/center.service";
import { ExportToCsv } from "export-to-csv-fix-source-map";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import UploadLabData from "./UploadLabData.modal";
import { useParams } from "react-router-dom";

interface IViewLabComputers {
    show: boolean;
    handleClose: any;
    data: any;
    selectedLabId: string,
}


export default function ViewLabComputers(props: IViewLabComputers) {

    let csvOptions: any = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const [uploadCsvData, setUploadCsvData] = useState<any>(undefined);



    const getComputerHistory = async (computer_mac: any) => {
        await CenterService.getComputerHistory(computer_mac).then(res => {
            if (res.status === 200) {
                if (res.data.length > 0) {
                    csvExporter.generateCsv(res.data.map((data: any) => {
                        return {
                            ...data,
                            screen_resolution: JSON.stringify(data.screen_resolution),
                            open_ports: JSON.stringify(data.open_ports)
                        }
                    }))
                }
                else {
                    toast.error("No History available")
                }
            }
        })
    }

    const downloadData = () => {
        csvExporter.generateCsv(props.data.computerIds.map((data: any) => {
            return {
                ...data,
                screen_resolution: JSON.stringify(data.screen_resolution),
                open_ports: JSON.stringify(data.open_ports)
            }
        }))
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Computer Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button size="sm" variant="outline-primary" onClick={downloadData}>Download Lab Data <FontAwesomeIcon icon={faDownload} /></Button>
                    <Button size="sm" variant="outline-warning" className="ms-2" onClick={() => setUploadCsvData(props.selectedLabId)}>Upload Lab Data Manually <FontAwesomeIcon icon={faUpload} /></Button>
                    <Card className="xcn-card mt-4">
                        <Table borderless striped>
                            <thead>
                                <tr>
                                    <th>cid</th>
                                    <th>mac</th>
                                    <th>open ports</th>
                                    <th>screen resolution</th>
                                    <th>History</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.computerIds?.map((dets: any) =>
                                    <tr className="xcn-table-tr">
                                        <td className="xcn-text-10 font-monospace">{dets.cid}</td>
                                        <td className="xcn-text-10 font-monospace">{dets.mac}</td>
                                        <td className="xcn-text-12 font-monospace">{dets.open_ports.length}</td>
                                        <td className="xcn-text-12 font-monospace">{JSON.stringify(dets.screen_resolution)}</td>
                                        <td><FontAwesomeIcon icon={faDownload} onClick={() => getComputerHistory(dets.mac)} /></td>
                                    </tr>
                                )
                                }

                            </tbody>
                        </Table>
                    </Card>
                </Modal.Body>
            </Modal>

            <UploadLabData
                show={uploadCsvData ? true : false}
                setShow={setUploadCsvData}
                labId={props.selectedLabId}
            />
        </>
    );
}
