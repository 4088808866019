import React, { useEffect, useState } from "react";
import { Container, Nav } from "react-bootstrap";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import IRouter from "../../interfaces/IRouter";

interface IXcnComponent {
  routes: IRouter[]
  navUrlPos: number
  baseUrl: string
}

export default function XcnComponent(props: IXcnComponent) {

  const location  = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(!location.pathname.split("/")[props.navUrlPos]){
        navigate(props.baseUrl + props.routes[0].path)
    }
},[])


  return (
    <>
        <Routes>
          {props.routes.map((data: IRouter, index: number)=>{
            return (
              <Route element={data.element}  path={data.path} />
            )
          })}
        </Routes>
    </>
  );
}
