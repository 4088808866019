import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { createDateFormat } from '../../helpers/time.helper';

interface IViewCentersTable {
    centers: any[]
}

export default function ViewCentersTable(props: IViewCentersTable) {

    const navigate = useNavigate();

    return (
        <div>
            <Table borderless striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Center Name</th>
                        <th>Center Id</th>
                        <th>Email</th>
                        <th>Pincode</th>
                        <th>Created On</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.centers.length > 0 ?
                            props.centers.map((data: any, index: number) => {
                                return (
                                    <tr className="xcn-table-tr" key={data._id}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td className="fw-bold xcn-text-12">
                                            {data.name}
                                        </td>
                                        <td className="fw-bold text-danger">
                                            {data.centerId}
                                        </td>
                                        <td className="font-monospace xcn-text-12">
                                            {data.centerEmail}
                                        </td>
                                        <td className="font-monospace xcn-text-12">
                                            {data.pincode}
                                        </td>
                                        <td className="fw-bold xcn-text-12 text-secondary">
                                            {createDateFormat(data.createdOn)}
                                        </td>
                                        <td>
                                            <FontAwesomeIcon icon={faEye} className="text-info xcn-link-pointer" onClick={()=> navigate("/centerdash/"+data._id+"/")}/>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={5} className="text-danger fw-bold">
                                    No Centers Registered
                                </td>
                            </tr>
                    }

                </tbody>
            </Table>
        </div>
    )
}