import { useState } from "react"
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import "./dropzone.css"

interface IEmployeeImageUpload {
  fileSelectedHandler: any
  title?: any
}

export default function MyUploader({ fileSelectedHandler, title }: IEmployeeImageUpload) {

  return (
    <Dropzone onDrop={fileSelectedHandler}>
      {({ getRootProps, getInputProps }: any) => (
        <section>
          <div className="xcn-dropzone-small">
            <div {...getRootProps()}>
              <input {...getInputProps()} name="labPhoto" />
              <p className="mt-5">{title ? title : "Drag 'n' drop file."}</p>
              <FontAwesomeIcon icon={faFileUpload} className="text-secondary fs-3" />
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );
}
