import { Button, Form, Table } from 'react-bootstrap';
import XcnModal from './XcnModal';
import { useState } from 'react';
import MyUploader from '../Dropzone/dropzone';
import { UploadService } from '../../services/upload.service';
import { toast } from 'react-toastify';

interface IAddLabToFloor {
    floorId: any
    onSave: any
    onClose: any
}


export default function AddFloorLab(props: IAddLabToFloor) {

    const [name, setName] = useState<string>("");
    const [computerCount, setComputerCount] = useState<any>(0);
    const [dvrCount, setDvrCount] = useState<any>(0);
    const [cameraCount, setCameraCount] = useState<any>(0);

    const [labImage, setLabImage] = useState<any>();

    const onSave = () => {
        props.onSave(props.floorId, { name, computerCount, dvrCount, cameraCount, labImage });
    }

    const fileSelectedHandler = async (e: any, name?: string) => {
        const fd = new FormData();
        for (let i of e) {
            fd.append("upload", i);
        }
        await UploadService.uploadFileToS3(fd).then((res: any) => {
            if (res.status === 200) {
                let labImage = res.data && res.data[0]
                setLabImage(labImage);
                toast.success(`File(s) uploaded`);
            } else {
                toast.error("Something went wrong");
            }
        });
    };

    return (
        <>
            <XcnModal
                show={props.floorId ? true : false}
                handleClose={props.onClose}
                body={
                    <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Lab Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Lab Name" onChange={(e: any) => setName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Computer Count</Form.Label>
                            <Form.Control type="number" placeholder="Enter Computer Count" onChange={(e: any) => setComputerCount(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>DVR Count</Form.Label>
                            <Form.Control type="number" placeholder="Enter DVR Count" onChange={(e: any) => setDvrCount(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Camera Count</Form.Label>
                            <Form.Control type="number" placeholder="Enter Camera Count" onChange={(e: any) => setCameraCount(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Upload Lab Image</Form.Label>
                            <MyUploader
                                fileSelectedHandler={(e: any) =>
                                    fileSelectedHandler(e, "labImage")
                                }
                            />
                        </Form.Group>
                        <Button onClick={onSave}>
                            Save
                        </Button>
                        <Button className="mx-3" variant="danger" onClick={props.onClose}>
                            Close
                        </Button>
                    </>
                }
                heading="Add Lab Data"
            />
        </>
    )
}