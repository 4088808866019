import React from "react";
import { Outlet, useParams } from "react-router-dom";
import TopBar from "../../../components/Navbars/TopBar";
import XcnComponent from "../../../components/XcnComponent/XcnComponent";
import employeeRoutes, {
  employeeBaseUrl,
} from "../../../router/routes/centerDash/employee.routes";

export default function EmployeeIndex() {
  const params: any = useParams();

  return (
    <>
      <TopBar
        routes={employeeRoutes}
        baseUrl={employeeBaseUrl(params.centerId)}
        navUrlPos={3}
      />

      <XcnComponent
        routes={employeeRoutes}
        baseUrl={employeeBaseUrl(params.centerId)}
        navUrlPos={3}
      />
      <Outlet />
    </>
  );
}
