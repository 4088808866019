import React, { useEffect, useState } from 'react';
import { faAdd, faEllipsisVertical, faEye, faInfo, faInfoCircle, faPlus, faPlusCircle, faTimesCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AddFloorToBuildingModal from '../Modals/AddFloor.modal';
import InfoModal from '../Modals/BuildingInfo.modal'
import BooleanStatus from '../status/BooleanStatus';
import CenterService from '../../services/center.service'
import { toast } from 'react-toastify';
import ViewFloorModal from '../Modals/ViewFloor.modal';
import CustomToggle from '../Menu/CustomMenu';
import BuildingImagesModal from '../Modals/BuildingImages.modal';

interface ICenterBuildingsTable {
    buildings: any[]
    refresh: any
    onSendData: any
}

export default function CenterBuildingsTable(props: ICenterBuildingsTable) {
    const [showAddFloor, setShowAddFloor] = useState<boolean>(false);
    const [showBuildingImage, setShowBuildingImage] = useState<boolean>(false);
    const [showViewFloor, setShowViewFloor] = useState<boolean>(false);
    const [showBuildingInfo, setShowBuildingInfo] = useState<boolean>(false)

    const [selectedBuilding, setSelectedBuilding] = useState<string>("");
    const [selectedFloorData, setSelectedFloorData] = useState<any>({});

    const params: any = useParams();


    const removeCenterBuilding = async (id: any) => {
        await CenterService.removeBuilding(id).then((res) => {
            if (res.status === 200) {
                props.onSendData();
                toast.success("Deleted Successfully");
            }
        })
            .catch((err) => {
                toast.error("Error", err.response.status);
            })
    }

    useEffect(() => {
        props.refresh(String(Math.random()))
    }, [showAddFloor, showViewFloor])

    return (
        <>
            <AddFloorToBuildingModal
                showAddBuildingPlan={showAddFloor}
                setShowAddBuildingPlan={setShowAddFloor}
                buildingId={selectedBuilding}
                centerId={params.centerId}
            />

            <ViewFloorModal
                showViewBuildingPlan={showViewFloor}
                setShowViewBuildingPlan={setShowViewFloor}
                buildingId={selectedBuilding}
                centerId={params.centerId}
                floorData={selectedFloorData}
            />

            <BuildingImagesModal
                showBuildingImage={showBuildingImage}
                setShowBuildingImage={setShowBuildingImage}
                buildingId={selectedBuilding}
            />



            <Table borderless striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Floors</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.buildings && props.buildings.length > 0 ?
                            props.buildings.map((data: any, index: number) => {
                                console.log(data);
                                return (
                                    <tr className="xcn-table-tr" key={data._id}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td className="fw-bold xcn-text-12">
                                            {data.name}
                                        </td>
                                        <td className="font-monospace xcn-text-12">
                                            {data.location}
                                        </td>
                                        <td className="fw-bold text-secondary">
                                            {data.floors.length}
                                            <FontAwesomeIcon icon={faEye} className="ms-1 text-info xcn-link-pointer" onClick={() => {
                                                setSelectedBuilding(data._id)
                                                setSelectedFloorData(data.floors)
                                                setShowViewFloor(true)
                                            }} />

                                        </td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    as={CustomToggle}
                                                    id="dropdown-custom-components"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisVertical}
                                                    />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => setShowBuildingInfo(true)}>
                                                        <FontAwesomeIcon
                                                            icon={faInfoCircle}
                                                            className="text-info xcn-link-pointer"
                                                        />
                                                        <span className="fw-bold text-secondary fs-12 ms-2">
                                                            Info
                                                        </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => {
                                                        setSelectedBuilding(data._id)
                                                        setShowAddFloor(true)
                                                    }}>
                                                        <FontAwesomeIcon
                                                            icon={faAdd}
                                                            className="text-secondary xcn-link-pointer"
                                                        />
                                                        <span className="fw-bold text-secondary fs-12 ms-2">
                                                            Add Floor
                                                        </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => {
                                                        setSelectedBuilding(data._id)
                                                        setShowBuildingImage(true)
                                                    }}>
                                                        <FontAwesomeIcon
                                                            icon={faUpload}
                                                            className="text-secondary xcn-link-pointer"
                                                        />
                                                        <span className="fw-bold text-secondary fs-12 ms-2">
                                                            Building Images
                                                        </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        className="text-danger  xcn-link-pointer"
                                                        onClick={() => removeCenterBuilding(data._id)}>
                                                        <FontAwesomeIcon
                                                            icon={faTimesCircle}
                                                        />
                                                        <span className="fw-bold text-danger fs-12 ms-2">
                                                            Delete
                                                        </span>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                        <InfoModal buildingInfo={showBuildingInfo} setBuildingInfo={setShowBuildingInfo} sendInfo={data.otherInfo} />
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={5} className="text-danger fw-bold">
                                    No Buildings Registered
                                </td>
                            </tr>
                    }
                </tbody>
            </Table>
        </>
    )
}