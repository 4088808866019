import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CenterService from '../../services/center.service';
import InputBox from '../Input/InputBox';
import XcnButton from '../XcnButton/XcnButton';
import XcnModal from './XcnModal';

interface IAddBuildingPlan {
    showAddBuildingPlan: any
    setShowAddBuildingPlan: any
}

export default function AddBuildingModal({
    showAddBuildingPlan,
    setShowAddBuildingPlan
}: IAddBuildingPlan) {

    const params: any = useParams();

    const [location, setLocation] = useState<string>();
    const [name, setName] = useState<string>();
    const [otherInfo, setOtherInfo] = useState<string>();

    const addBuilding = async () => {
        await CenterService.insertBuilding({ name, location, otherInfo }).then(res => {
            if (res.status === 200) {
                toast.success("Added Building Info")
                setShowAddBuildingPlan(false)
            }
        })
    }

    return (
        <>
            <XcnModal
                show={showAddBuildingPlan}
                handleClose={() => setShowAddBuildingPlan(false)}
                body={
                    <>
                        <InputBox
                            label="Building Name"
                            type="text"
                            onChange={(e: any) => setName(e.target.value)}
                        />
                        <InputBox
                            label="Location"
                            type="text"
                            onChange={(e: any) => setLocation(e.target.value)}
                            className='mt-3'
                        />
                        <InputBox
                            label="Other Info"
                            type="text"
                            onChange={(e: any) => setOtherInfo(e.target.value)}
                            className='mt-3'
                        />

                        <XcnButton
                            variant="secondary"
                            text={
                                <>
                                    Add Building
                                </>
                            }
                            size="sm"
                            className="mt-3"
                            onClick={addBuilding}
                        />
                    </>
                }
                heading="Add New Building"
            />
        </>
    )
}