import { faEdit, faEllipsisVertical, faEye, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState } from 'react';
import { Badge, Button, Dropdown, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import CustomToggle from '../Menu/CustomMenu';
import EditApprovalModal from '../Modals/EditApproval.modal';
import BooleanStatus from '../status/BooleanStatus';

interface ICenterApprovals {
  approvals: any[]
  reload: any,
  approvalValidUpto: any
}

export default function CenterApprovals(props: ICenterApprovals) {


  const [approvalIndex, setApprovalIndex] = useState<number>(-1)

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );
  return (
    <>
      <Table borderless striped>
        <thead>
          <tr>
            <th>Sr. No</th>
            <th>Approval Id</th>
            <th>Visited</th>
            <th>Total Nodes</th>
            <th>Approved/Rejected</th>
            <th>Approved Nodes</th>
            <th>Labs Approved</th>
            <th>Audit Reports</th>
            <th>Audit Remarks</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            props.approvals && props.approvals.length > 0 ?
              props.approvals.map((data: any, index: number) => {
                return (
                  <tr className="xcn-table-tr" key={data._id}>
                    <td>{index + 1}</td>
                    <td className="fw-bold xcn-text-12">
                      {data.approvalId}
                    </td>
                    <td>
                      <BooleanStatus
                        status={data.visited}
                        value={{
                          true: "Visited",
                          false: "Not Visited",
                        }}
                      />
                    </td>
                   
                    <td className="font-monospace xcn-text-12">
                      {data.nodesAvailable}
                    </td>
                    <td className="fw-bold font-monospace xcn-text-12 text-secondary">
                      {/* {moment(data.approvedOn).format(
                        "DD-MM-YYYY"
                      )} */}
                       {data.status === "pending" ? "--" : moment(data.approvedOn).format(
                        "DD-MM-YYYY"
                      )}
                    </td>
                    <td>{data.nodesApproved}</td>
                    <td>{data.numberofLabsApproved}</td>
                    <td>
                      {data.auditReport ? (
                        <a
                          className="text-decoration-none"
                          href={data.auditReport}
                          download
                        >
                          Preview
                        </a>
                      ) : (
                        "--"
                      )}
                    </td>
                    <td>
                      {data.remarks ? (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip(data.remarks)}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faMessage}
                              className="text-primary"
                            />
                          </span>
                        </OverlayTrigger>
                      ) : (
                        "--"
                      )}
                    </td>
                    <td>
                      <Badge>
                        {data.status && data.status.toUpperCase()}
                      </Badge>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                        >
                          <FontAwesomeIcon
                            icon={faEllipsisVertical}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setApprovalIndex(index)}>
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="text-info xcn-link-pointer"
                            />
                            <span className="fw-bold text-secondary fs-12 ms-2">
                              Edit
                            </span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              }) :
              <tr>
                <td colSpan={8} className="text-danger fw-bold">
                  No Approvals Registered
                </td>
              </tr>
          }

        </tbody>
      </Table>
      <EditApprovalModal
        show={approvalIndex >= 0 ? true : false}
        onClose={() => setApprovalIndex(-1)}
        data={props.approvals && props.approvals[approvalIndex]}
        reload={props.reload}
        approvalValidUpto={props.approvalValidUpto}
      />
    </>

  )
}