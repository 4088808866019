import { faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../../interfaces/IRouter';
// import AllLabs from '../../../views/CenterDash/CenterLabs/AllLabs/AllLabs';
import ViewLabDetails from '../../../views/CenterDash/CenterLabs/AllLabs/ViewLabDetails';
import AllComputers from '../../../views/CenterDash/CenterLabs/Computers/AllComputers';


export function centerLabsBaseUrl(centerId: string) {
    return "/centerlabs/"+centerId+"/"
}

const centerLabRoutes: IRouter[] = [
    {
        path: "all-labs",
        element: <ViewLabDetails/>,
        navbarShow: true,
        activeIcon: faHome,
        name: "Details"
    },
    // {
    //     path: "all-computers",
    //     element: <AllComputers/>,
    //     navbarShow: true,
    //     activeIcon: faHome,
    //     name: "allComputer"
    // },
    // {
    //     path: "labs/:labId",
    //     element: <AllComputers/>,
    //     navbarShow: false,
    //     activeIcon: faHome,
    //     name: "labData"
    // },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }
    
    ]

export default centerLabRoutes;