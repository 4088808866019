import moment from 'moment';

export function createDateForInput(date?: Date){
    return moment(date).format("YYYY-MM-D");
}

export function createAgeFormat(date: Date){
    return moment().diff(moment(date, "DD MMM YYYY"), 'years');
}

export function createDateFormat(date: Date){
    return moment(date).format("Do MMM YYYY");
}

export function createDateFormatWithTime(date: Date){
    return moment(date).format("Do MMM YYYY hh:mm a");
}