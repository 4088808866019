import { faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../../interfaces/IRouter';
import ViewBooking from '../../../views/CenterDash/CenterBookings/CreateBooking/CreateBooking';
import CenterApprovalInfo from '../../../views/CenterDash/CenterDetails/CenterApprovalInfo/CenterApprovalInfo';


export function centerBookingBaseUrl(centerId: string) {
    return "/centerdash/" + centerId + "/"
}

const centerBookingRoutes: IRouter[] = [
    {
        path: "view-all",
        element: <ViewBooking />,
        navbarShow: true,
        activeIcon: faHome,
        name: "View All"
    },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }

]

export default centerBookingRoutes;