import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col, Modal, FloatingLabel, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputBox from '../../components/Input/InputBox';
import XcnButton from '../../components/XcnButton/XcnButton';
import XcnModal from './XcnModal';
import CenterService from '../../services/center.service'
import DatePicker from "react-datepicker"


interface IAddBooking {
    showAddBooking: any
    setShowAddBooking: any
}

interface IBooking {
    bookingDate: string;
    bookingStartTime: string;
    bookingEndTime: string;
    examId: string;
    examName: string;
    nodesAlloted: string;
    centerId: string;
}

export default function AddBooking({ showAddBooking, setShowAddBooking }: IAddBooking) {

    const getAllCenters = async () => {
        await CenterService.getAllCenters().then(res => {
            if (res.status === 200) {
                setAddExamBooking({ ...addExamBooking, centerId: (res.data.find((obj: any) => obj._id === params.centerId).centerId) });
            }
        })
    }

    useEffect(() => {
        getAllCenters();
    }, [])

    const params: any = useParams();

    const [bookingStartTime, setBookingStartTime] = useState<any>(new Date())
    const [bookingEndTime, setBookingEndTime] = useState<any>(new Date())
    const [bookingDate, setBookingDate] = useState<any>(new Date())

    const [addExamBooking, setAddExamBooking] = useState<IBooking>({
        bookingDate: (new Date()).toISOString(),
        bookingStartTime: (new Date()).toISOString(),
        bookingEndTime: (new Date()).toISOString(),
        examId: '',
        examName: '',
        nodesAlloted: '',
        centerId: ''
    });

    const handleInputChange = (e: any) => {
        setAddExamBooking({ ...addExamBooking, [e.target.name]: e.target.value });
    }

    console.log(addExamBooking)

    const addBookingHandler = async () => {
        await CenterService.createBooking(addExamBooking).then((res) => {
            console.log(res)
            if (res.status === 200) {
                toast.success("Booking successful..");
            }
        }).catch((err) => {
            toast.error("Booking failed", err.response.error);
        })
    }

    return (
        <>
            <Modal
                show={showAddBooking}
                onHide={() => setShowAddBooking(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add New Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Nodes Alloted"
                            className="mb-3"
                        >
                            <Form.Control type="number" name="nodesAlloted" onChange={handleInputChange} />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Exam Id"
                            className="mb-3"
                        >
                            <Form.Control type="text" name="examId" onChange={handleInputChange} />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Exam Name"
                            className="mb-3"
                        >
                            <Form.Control type="text" name="examName" onChange={handleInputChange} />
                        </FloatingLabel>
                        <div>
                            <Form.Label className="fw-bold xcn-text-12 text-secondary">
                                Booking Date
                            </Form.Label>
                            <DatePicker selected={bookingDate} name="approvedOn" onChange={(e: Date) => {
                                setBookingDate(e)
                                setAddExamBooking({ ...addExamBooking, bookingDate: e.toISOString() });
                            }} />
                        </div>
                        <div className="mt-3">

                            <Form.Label className="fw-bold xcn-text-12 text-secondary">
                                Booking Start Time - Booking End Time
                            </Form.Label>

                            <div className="d-flex align-items-center">

                                <DatePicker
                                    selected={bookingStartTime}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    onChange={(e: Date) => {
                                        setBookingStartTime(e);
                                        setAddExamBooking({ ...addExamBooking, bookingStartTime: e.toISOString() });
                                    }} />

                                <div className="fw-bold mx-3"> - </div>
                                <DatePicker
                                    selected={bookingEndTime}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    onChange={(date: Date) => {
                                        console.log(date)
                                        setBookingEndTime(date)
                                        setAddExamBooking({ ...addExamBooking, bookingEndTime: date.toISOString() });
                                    }} />


                            </div>
                        </div>
                    </div>
                    <Button className="primary mt-3" onClick={addBookingHandler}>Add Booking</Button>
                </Modal.Body>
            </Modal>
        </>
    )
}