export enum WorkerCategoryEnum {
    SOFTWARE_SUPPORT_ENGINERR = 'software_support_engineer',
    NETWORK_AND_HARDWARE_SUPPORT="network_and_hardware_support",
    REGISTRATIONMANAGER = "registration_manager",
    SURVEILLANCE_MONITOR = "surveillance_monitor",
    SECURITY_AND_FRISKING = "security_and_frisking", 
    HOUSEKEEPING_STAFF = "housekeeping_staff", 
    INVIGILATOR = "invigilator", 
    LAB_SUPERVISOR = "lab_supervisor",
    NOT_AVAILABLE = "not_available"
}