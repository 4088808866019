import { METHODS } from "http";
import makeRequest from "./makeRequest";
import urls from "./urls";

export class UploadService {
    static async uploadFileToS3(formData:any) {
        return await makeRequest(
          "/centerAdmin/uploadFileToS3",
          "POST",
          formData
        );
    }
}