import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import InputBox from "../Input/InputBox";
import XcnButton from "../XcnButton/XcnButton";
import XcnModal from "./XcnModal";
import DateTime from "react-datetime";
import DatePicker from "react-datepicker";

import { toast } from "react-toastify";
import MyUploader from "../Dropzone/dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import CenterService from "../../services/center.service";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

interface IAddApprovals {
  show: any;
  onClose: any;
  reload?: any;
  data: any;
  approvalValidUpto: any;
}

const EditApprovalModal = ({
  show,
  onClose,
  reload,
  data,
  approvalValidUpto,
}: IAddApprovals) => {
  const [approvedOn, setApprovedOn] = useState<any>();
  const [approvalValid, setApprovalValid] = useState<any>();
  const [auditUpload, setAuditUpload] = useState<any>();
  const [approvalsData, setApprovalsData] = useState<any>();

  const handleInputChange = (e: any) => {
    let newData: any;
    if (e.target.type === "checkbox") {
      newData = {
        ...approvalsData,
        [e.target.name]: e.target.checked,
      };
    } else {
      newData = {
        ...approvalsData,
        [e.target.name]: e.target.value,
      };
    }
    if(data.status === "approved" || data.staus === "rejected"){
      newData = {
        ...approvalsData,
        status: "pending"
      }
    }
    setApprovalsData(newData);
  };

  useEffect(() => {
    setApprovalsData(data);
  }, [show]);

  const handleApprovalSubmission = async () => {
    let formData: any = new FormData();

    formData.append(
      "upload",
      auditUpload ? auditUpload : approvalsData.auditReport
    );
    formData.append("visited", approvalsData && approvalsData.visited);
    formData.append(
      "nodesAvailable",
      approvalsData && approvalsData.nodesAvailable
    );
    formData.append(
      "nodesApproved",
      approvalsData && approvalsData.nodesApproved
    );
    formData.append(
      "numberofLabsApproved",
      approvalsData && approvalsData.numberofLabsApproved
    );
    formData.append(
      "approvedOn",
      approvedOn ? approvedOn.toISOString() : approvalsData.approvedOn
    );
    formData.append(
      "approvalValidUpto",
      approvalValid ? approvalValid.toISOString() : approvalValidUpto
    );
    formData.append("remarks", approvalsData && approvalsData.remarks);
    formData.append("status", approvalsData && approvalsData.status);
    toast.promise(
      CenterService.updateApprovalsInfo(approvalsData._id, formData).then(
        (res) => {
          if (res.status === 200) {
            onClose();
            reload();
          }
        }
      ),
      {
        success: "Approval updated successfully",
        error: "Error while uploading",
        pending: "Loading...",
      }
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FloatingLabel
              controlId="floatingInput"
              label="Approved node"
              className="mb-3"
            >
              <Form.Control
                type="number"
                name="nodesApproved"
                defaultValue={approvalsData && approvalsData.nodesApproved}
                onChange={handleInputChange}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Available Nodes"
              className="mb-3"
            >
              <Form.Control
                type="number"
                name="nodesAvailable"
                defaultValue={approvalsData && approvalsData.nodesAvailable}
                onChange={handleInputChange}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="No. of Labs Approved"
              className="mb-3"
            >
              <Form.Control
                type="number"
                name="numberofLabsApproved"
                defaultValue={
                  approvalsData && approvalsData.numberofLabsApproved
                }
                onChange={handleInputChange}
              />
            </FloatingLabel>
            <div className="border mb-3 text-muted">
              <Form.Group className="d-flex align-items-center ms-2 p-2">
                <Form.Label>Visited</Form.Label>
                <Form.Check
                  className="ms-2 mb-2"
                  type="switch"
                  name="visited"
                  checked={approvalsData && approvalsData.visited}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group>
                <Form.Label className="fw-bold xcn-text-12 text-secondary mb-3">
                  Upload Center Audit
                  {approvalsData && approvalsData.auditReport && (
                    <a
                      className="text-decoration-none"
                      href={approvalsData && approvalsData.auditReport}
                    >
                      <span className="text-success text-decoration-none xcn-link-pointer ms-2">
                        View/Download <FontAwesomeIcon icon={faDownload} />
                      </span>
                    </a>
                  )}
                </Form.Label>
                <MyUploader
                  fileSelectedHandler={(e: any) => setAuditUpload(e[0])}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group>
                <Form.Label className="fw-bold xcn-text-12 text-secondary mb-3">
                  {" "}
                  Center Remarks
                </Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ height: "80px" }}
                  name="remarks"
                  defaultValue={approvalsData && approvalsData.remarks}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>
            <div className="mt-3">
              <Form.Label className="fw-bold xcn-text-12 text-secondary">
                Approval Start Time - Approval End Time
              </Form.Label>
              <div className="d-flex align-items-center">
                <DatePicker
                  className="form form-control"
                  // selected={approvedOn}
                  selected={
                    approvalsData && approvalsData.approvedOn
                      ? moment(approvalsData.approvedOn).toDate()
                      : approvedOn
                  }
                  name="approvedOn"
                  onChange={(e: Date) => setApprovedOn(e)}
                />
                <div className="fw-bold mx-3"> - </div>
                {/* <DateTime onChange={(e: any) => handleDateChange('endTime', e)} /> */}
                <DatePicker
                  className="form form-control"
                  selected={
                    approvalValidUpto
                      ? moment(approvalValidUpto).toDate()
                      : approvalValid
                  }
                  name="approvalValidUpto"
                  onChange={(date: Date) => setApprovalValid(date)}
                />
              </div>
            </div>
          </div>
          <Button className="primary mt-3" onClick={handleApprovalSubmission}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditApprovalModal;
