import React from 'react';


import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import IRouter from '../../interfaces/IRouter';

import './TopBar.css';

export interface ITopBar {
    routes: IRouter[]
    baseUrl: string
    navUrlPos: number
}

export default function TopBar({ routes, baseUrl, navUrlPos }: ITopBar) {
    let navigate = useNavigate();

    return (
        <>
            <Navbar bg="white" variant="light" className="xcd-navbar">
                <Nav className="me-auto">

                    {
                        routes.map((data: IRouter, index: number) => {
                            if (data.navbarShow == true) {
                                return (
                                    <span key={data.path}>
                                        {
                                            data.path === window.location.pathname.split("/")[navUrlPos] ?
                                                <Nav.Link className="fw-bold ms-1" active>{data.name}</Nav.Link> :
                                                <Nav.Link className="fw-bold ms-1" onClick={() => navigate(data.path) }>{data.name}</Nav.Link>
                                        }
                                    </span>
                                )
                            }

                        })
                    }
                </Nav>
            </Navbar>
        </>

    )
}