import { faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../../interfaces/IRouter';
import ViewEmployeeDetails from '../../../views/CenterDash/Employee/AllEmployee/ViewEmployeeDetails';


export function employeeBaseUrl(employeeId: string) {
    return "/employee/"+employeeId+"/"
}

const employeeRoutes: IRouter[] = [
    {
        path: "all-employee",
        element: <ViewEmployeeDetails/>,
        navbarShow: true,
        activeIcon: faHome,
        name: "Details"
    },
    
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }
    
    ]

export default employeeRoutes;